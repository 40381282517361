@use 'sass:map';

@function gu-color($map, $hue) {
    @return map.get($map, $hue);
}

@function gu-contrast($map, $hue) {
    $contrast: map.get($map, contrast);
    @return map.get($contrast, $hue);
}

$gu-primary: (
    50: #f1e8f6,
    100: #dcc6e9,
    200: #c5a1da,
    300: #ae7bcb,
    400: #9c5ec0,
    500: #8b42b5,
    600: #833cae,
    700: #7833a5,
    800: #6e2b9d,
    900: #5b1d8d,
    A100: #e5c8ff,
    A200: #cc95ff,
    A400: #b462ff,
    A700: #a848ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff
    )
);

body {
    --color-primary: #{gu-color($gu-primary, 500)};
    --color-primary-lighter: #{gu-color($gu-primary, 200)};
    --color-primary-darker: #{gu-color($gu-primary, 800)};
    --color-contrast-primary: #{gu-contrast($gu-primary, 500)};
    --color-contrast-primary-lighter: #{gu-contrast($gu-primary, 200)};
    --color-contrast-primary-darker: #{gu-contrast($gu-primary, 800)};

    --mdc-filled-text-field-container-color: #ffffff1a;
}

$gu-accent: (
    50: #fbe2e7,
    100: #f4b8c3,
    200: #ed889b,
    300: #e65873,
    400: #e03555,
    500: #db1137,
    600: #d70f31,
    700: #d20c2a,
    800: #cd0a23,
    900: #c40516,
    A100: #ffffff,
    A200: #ea9dd9,
    A400: #cf89bf,
    A700: #875a7d,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff
    )
);

body {
    --color-accent: #{gu-color($gu-accent, 500)};
    --color-accent-lighter: #{gu-color($gu-accent, A200)};
    --color-accent-darker: #{gu-color($gu-accent, A700)};
    --color-contrast-accent: #{gu-contrast($gu-accent, 500)};
    --color-contrast-accent-lighter: #{gu-contrast($gu-accent, A200)};
    --color-contrast-accent-darker: #{gu-contrast($gu-accent, A700)};
}

$gu-text-color: white;
$gu-accent-lighter: gu-color($gu-accent, A200);
$gu-foreground: (
    base: $gu-text-color,
    divider: $gu-accent-lighter,
    dividers: $gu-accent-lighter,
    disabled: rgba($gu-text-color, 0.38),
    disabled-button: rgba($gu-text-color, 0.38),
    disabled-text: rgba($gu-text-color, 0.38),
    hint-text: $gu-accent-lighter,
    secondary-text: rgba($gu-text-color, 0.54),
    icon: rgba($gu-text-color, 0.54),
    icons: rgba($gu-text-color, 0.54),
    text: rgba($gu-text-color, 0.87),
    slider-off: rgba($gu-text-color, 0.26),
    slider-off-active: rgba($gu-text-color, 0.38),
    slider-min: rgba($gu-text-color, 0.38)
);

body {
    --color-text: #{$gu-text-color};
    --hint-text: #{$gu-accent-lighter};
}

$gu-palettes: (
    accent: $gu-accent,
    primary: $gu-primary,
    foreground: $gu-foreground
);

@function get($p) {
    @return map.get($gu-palettes, $p);
}
