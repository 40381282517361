.cc-theme-pink {
    --cc-bg: #0e1011;
    --cc-text: #d8e5ea;
    --cc-link: #ea9dd9;
    --cc-btn-primary-bg: #db1137;
    --cc-btn-primary-text: #d8e5ea;
    --cc-btn-primary-hover-bg: #cd0a23;
    --cc-btn-secondary-bg: #242c31;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #2b353c;
    --cc-toggle-bg-off: #667481;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #343e45;
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-cookie-category-block-bg: #1e2428;
    --cc-cookie-category-block-bg-hover: #242c31;
    --cc-section-border: #222a30;
    --cc-block-text: #bac9cf;
    --cc-cookie-table-border: #2b3035;
    --cc-overlay-bg: rgba(4, 6, 8, 0.85);
    --cc-webkit-scrollbar-bg: #2c343a;
    --cc-webkit-scrollbar-bg-hover: #384148;
}

.cc-theme-pink .cc_div a {
    color: var(--cc-btn-primary-bg);
}

.cc-theme-pink #cc_div #s-hdr {
    border-bottom: none;
}

.cc-theme-pink .cc_div #c-txt {
    color: #b0b8c6 !important;
}

.cc-theme-pink a.cc_link {
    color: var(--cc-link);
    border: 0 !important;

    &:hover {
        color: var(--cc-link);
        text-decoration: underline;
    }
}
