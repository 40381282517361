@use 'sass:map';
@use '@angular/material' as mat;
@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/virtual/virtual.scss';
@import '../../node_modules/swiper/modules/pagination/pagination.scss';
@import '../../node_modules/swiper/modules/navigation/navigation.scss';

@mixin bullet($color) {
    margin: 0 4px;
    height: 3px;
    width: 58px;
    display: inline-block;
    background-color: $color;
}

gu-jumbotron {
    background-color: black;

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        max-width: 1100px;
        max-height: 500px;
        position: relative;

        & > .gu-jumbotron-preview > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .gu-pagination-bullet {
        @include bullet(var(--accent-lighter));
        opacity: 0.2;
    }

    .gu-pagination-active-bullet {
        @include bullet(var(--accent-lighter));
        opacity: 1;
    }
}
