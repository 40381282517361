.swiper-virtual .swiper-slide {
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}
.swiper-virtual.swiper-css-mode {
  .swiper-wrapper::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  &.swiper-horizontal .swiper-wrapper::after {
    height: 1px;
    width: var(--swiper-virtual-size);
  }
  &.swiper-vertical .swiper-wrapper::after {
    width: 1px;
    height: var(--swiper-virtual-size);
  }
}
