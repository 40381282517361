@use '@angular/material' as mat;
@use './material-icons';
@use './mat-palette.theme' as palette;
@use './mat-expansion-panel.theme';
@use './mat-button-toggle.theme';
@use './cc.theme';
@use './plyr.theme';
@use './swiper.theme';

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/open-sans/OpenSans-VariableFont_wdth\,wght.ttf')
        format('woff2-variations');
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/open-sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf')
        format('woff2-variations');
    font-display: swap;
    font-style: italic;
}

$gu-typography: mat.define-typography-config(
    $font-family: "'Open Sans', sans-serif",
    $headline-5: mat.define-typography-level(38px, 42px, 500),
    $headline-6: mat.define-typography-level(32px, 32px, 500),
    $subtitle-1: mat.define-typography-level(18px, 18px, 400),
    $body-1: mat.define-typography-level(16px, 20px, 400),
    $body-2: mat.define-typography-level(16px, 20px, 400),
    $caption: mat.define-typography-level(10px, 12px, 400),
    $button: mat.define-typography-level(14px, 16px, 400)
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($gu-typography);`
@include mat.all-component-typographies($gu-typography);
@include mat.core();

$gu-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: mat.define-palette(palette.get('primary'), 500, 200, 800),
            accent: mat.define-palette(palette.get('accent'), 500, A200, A700),
            foreground: palette.get('foreground')
        ),
        typography: $gu-typography
    )
);

@include mat.all-component-themes($gu-dark-theme);
