/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './themes/grown-ups.theme';

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    color: var(--color-text);
    font-family: 'Open Sans', sans-serif;
    --swiper-theme-color: #db1137;
    --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
    --mat-sidenav-container-background-color: #231721;
}

h1,
h2 {
    text-transform: uppercase;
}

h3 {
    text-transform: uppercase;
    color: var(--color-accent-lighter);
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    line-height: 1.5em;
}

footer {
    padding-top: 40px;
    background-color: #140d14;
}

.gu-link-disabled {
    color: #6e8093;
    opacity: 0.7;
    cursor: default;

    &:hover {
        text-decoration: none !important;
    }
}

.gu-column {
    max-width: 1100px;
    min-width: 300px;
    margin: 0 auto;
    position: relative;
}

.gu-tab-content {
    padding: 50px 0;
}

.gu-column-top {
    padding-top: 60px;
}

.gu-italic {
    font-style: italic;
}

.gu-section {
    padding: 0;
    margin-bottom: 80px;

    @media screen and (max-width: 1099px) {
        margin-bottom: 20px;
        padding: 0 16px;
    }
}

.gu-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.gu-bold {
    font-weight: bold;
}

.gu-debug {
    background-color: aquamarine;
    border: 1px solid plum;
}

.gu-text-center {
    text-align: center;
}

.gu-accent {
    color: var(--color-accent);
}

.gu-primary {
    color: var(--color-primary);
}

.gu-stretch {
    width: 100%;
}

.gu-accent-lighter {
    color: var(--color-accent-lighter);
}

.gu-card {
    background-color: rgba($color: black, $alpha: 0.6);
    padding: 24px;
}

.gu-text {
    color: var(--color-text);
}

.gu-relative {
    position: relative;
}

.gu-page-min-height {
    min-height: 700px;
}

.gu-link {
    outline: 0;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-text);

    &-accent-lighter {
        color: var(--color-accent-lighter);
    }

    &:visited {
        color: inherit;
    }

    &:hover {
        text-decoration: underline;
    }
}

.gu-small {
    font-size: 0.8em;
}

.gu-primary-chip {
    background-color: var(--color-primary) !important;
    color: var(--color-contrast-primary) !important;
}

.gu-library-notice {
    font-size: 0.7em;
    text-transform: uppercase;
    color: var(--color-accent-lighter);
}

.gu-error-snackbar {
    background-color: #f44336;
    color: white;
}

.gu-hint {
    font-size: 0.8em;
}

.gu-stretch {
    width: 100%;
}

.gu-alternate-section {
    padding: 20px 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    width: 100%;
    margin-bottom: 16px;
}

.gu-success-snackbar {
    background-color: #66bb6a;
    color: white;
}

.gu-error {
    color: #f44336;
}

.gu-success {
    color: #66bb6a;
}

.gu-error-container {
    margin: 16px 0 0 0;
}

.gu-markdown-page {
    padding-top: 40px;
}

.gu-decorated-header {
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(to right, #7e317a, #c86a60) 1;
    padding-bottom: 12px !important;
}

.gu-markdown {
    line-height: 1.6em;
    font-size: 1.2em;
    font-weight: 400;

    p {
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 1em;
        font-style: normal;
        font-size: 2.2em;
        font-weight: inherit;
    }

    h2 {
        margin-bottom: 1em;
        margin-top: 1em;
        font-style: normal;
        font-weight: inherit;
    }

    h3 {
        margin-top: 1.6em;
        margin-bottom: 1em;
        line-height: 1.4em;
        font-size: 1.2em;
        font-style: normal;
        font-weight: inherit;
    }

    h4 {
        margin-top: 1em;
        margin-bottom: 0.8em;
        color: inherit;
        font-size: 1.3em;
        font-weight: inherit;
        font-style: normal;
    }

    h2,
    h3,
    h4,
    h5 {
        background-color: initial;
        color: var(--color-accent-lighter);
        padding: initial;
    }

    a {
        color: var(--color-accent-lighter);
    }

    blockquote {
        padding-left: 20px;
        border-left: 6px solid var(--color-accent-lighter);
    }

    strong {
        font-weight: 700;
    }

    code {
        color: #6e8093;
    }

    img {
        max-width: 100%;
    }
}

.mat-success {
    background-color: theme('colors.mintGreen');
}
