mat-expansion-panel.gu-payment-selection {
    border-radius: 0;
    background-color: rgba(black, 0.3);

    & > .mat-expansion-panel-header {
        min-height: 60px;

        &.no-events {
            pointer-events: none;
        }
    }
}
